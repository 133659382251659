import './App.css';
import {Front} from './Front';
import {Expertise} from './Expertise';
import {Projects} from './Projects';
import {Navbar} from './Navbar';
import { Contact } from './Contact';
import BG from './BG'; 
// import Lenis from 'lenis'
import LocomotiveScroll from 'locomotive-scroll';
import React, {useEffect} from 'react'


function App() {

useEffect(() => {
    if (window.location.pathname === "/nst-secret") {
      window.location.href =
        "https://www.newtonschool.co/newton-school-of-technology-nst/apply-referral/?utm_source=referral&utm_medium=uditjain&utm_campaign=btech-computer-science-portal-referral";
    }
    if (window.location.pathname === "/xy") {
      window.location.href =
        "https://my.newtonschool.co/playground/code/yn4ov7ypekmz";
    }
  }, []);

    // const lenis = new Lenis({
    //   autoRaf: true,
    // });

    // lenis.on('scroll', (e) => {
    //   console.log(e);
    // });

    
  if(window.innerWidth>700){
    console.log("smooth scroll enabled")
    const locomotiveScroll = new LocomotiveScroll({
      lenisOptions: {
          lerp: 0.1,
          duration: 1.2,
          orientation: 'vertical',
          gestureOrientation: 'vertical',
          smoothWheel: true,
          smoothTouch: false,
          wheelMultiplier: 1,
          touchMultiplier: 2,
          normalizeWheel: true,
          easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
      },
  });
  }else{
    console.log("smooth scroll disabled")
  }




  return (
    <div className="App">
      
      <BG/>
      <Navbar/>
      <Front />
      <Expertise />
      <Projects />
      <Contact/>

    </div>
  );
}



export default App;
